<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <span v-if="error">
          <app-alert @dismissed="onDismissed" :text="error" text_style="error" />
        </span>
        <v-card class="elevation-4">
          <v-card-text align="center">
            <v-img src="@/assets/images/VentureWallet_icon_light.png" height="80px" contain />
          </v-card-text>
          <v-card-text align="center">
            <h1>Sign In</h1>
          </v-card-text>
          <v-spacer />

          <v-card-text>
            <v-form ref="form" @submit.prevent="signin">
              <v-text-field
                label="Email"
                name="email"
                prepend-icon="person"
                type="text"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                v-model="email"
                required
              />
              <v-text-field
                label="Password"
                name="password"
                prepend-icon="lock"
                type="password"
                :error-messages="passwordErrors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                v-model="password"
                required
              />
              <v-btn type="submit" dark class="primary" x-large block :loading="isLoading">Sign In</v-btn>
            </v-form>
          </v-card-text>
          <v-card-text align="center">
            <p class="mb-1">Or, sign in with your social account:</p>
            <div class="equal_spaced" style="text-align: center;">
              <button class="loginBtn--google" @click="googleLogin">
                <v-container />
              </button>
            </div>

            <router-link to="/forgotpassword" class="routerlink">Forgot your password?</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <p style="text-align: center;">
      Don't have an account yet?
      <router-link to="/register" class="routerlink">
        <strong>Create your account here.</strong>
      </router-link>
    </p>
  </v-container>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/auth";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "Signin",
  mixins: [validationMixin],

  validations: {
    password: { required, minLength: minLength(6) },
    email: { required, email }
  },
  data: function() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 6 characters");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    ...mapGetters(["isLoading", "error"])
  },

  methods: {
    signin: function(e) {
      const user = {
        email: this.email,
        password: this.password
      };
      this.$store.dispatch("signInAction", user);
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    googleLogin() {
      this.$store.dispatch("googleSignInAction");
    }
  }
};
</script>
